<template>
  <footer>
    <a
      href="https://api.whatsapp.com/send?phone=5511910877390&text=Quero%20mergulhar!"
      target="_blank"
      class="wppFixed"
    >
      <!-- <svg
        enable-background="new 0 0 512 512"
        class="wppIcon"
        version="1.1"
        viewBox="0 0 512 512"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M256.064,0h-0.128l0,0C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104  l98.4-31.456C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z"
          fill="#4CAF50"
        />
        <path
          d="m405.02 361.5c-6.176 17.44-30.688 31.904-50.24 36.128-13.376 2.848-30.848 5.12-89.664-19.264-75.232-31.168-123.68-107.62-127.46-112.58-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624 26.176-62.304c6.176-6.304 16.384-9.184 26.176-9.184 3.168 0 6.016 0.16 8.576 0.288 7.52 0.32 11.296 0.768 16.256 12.64 6.176 14.88 21.216 51.616 23.008 55.392 1.824 3.776 3.648 8.896 1.088 13.856-2.4 5.12-4.512 7.392-8.288 11.744s-7.36 7.68-11.136 12.352c-3.456 4.064-7.36 8.416-3.008 15.936 4.352 7.36 19.392 31.904 41.536 51.616 28.576 25.44 51.744 33.568 60.032 37.024 6.176 2.56 13.536 1.952 18.048-2.848 5.728-6.176 12.8-16.416 20-26.496 5.12-7.232 11.584-8.128 18.368-5.568 6.912 2.4 43.488 20.48 51.008 24.224 7.52 3.776 12.48 5.568 14.304 8.736 1.792 3.168 1.792 18.048-4.384 35.52z"
          fill="#FAFAFA"
        />
      </svg> -->
      <svg viewBox="0 0 32 32" class="wppIcon">
        <path
          d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
          fill-rule="evenodd"
        ></path>
      </svg>
    </a>
    <button class="top" @click="scrollToTop">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="topIcon"
        viewBox="0 0 24 24"
        fill="none"
        stroke-width="2"
        stroke-linecap="square"
        stroke-linejoin="arcs"
      >
        <path d="M18 15l-6-6-6 6" />
      </svg>
    </button>
    <section
      class="containerInfo"
      itemscope
      itemtype="http://schema.org/PostalAddress"
    >
      <article class="infosItem">
        <div class="infoItens">
          <figure>
            <img
              src="@/assets/icon/svg/locationIcon.svg"
              class="img"
              alt="Endereço da Scubalab"
            />
          </figure>
          <p itemprop="streetAddress">
            Rua: Quinze de Agosto- n°77 - Centro - Cep 09721-110
          </p>
        </div>
        <div class="infoItens">
          <figure>
            <img
              src="@/assets/icon/svg/phoneIcon.svg"
              class="img"
              alt="Telefone Scubalab"
            />
          </figure>
          <p itemprop="telephone">Tel.: (11)4339-7390 | (11) 4930-1002</p>
        </div>
        <div class="infoItens">
          <figure>
            <img
              src="@/assets/icon/svg/wppIcon.svg"
              class="img"
              alt="Whatsapp Scubalab"
            />
          </figure>
          <p itemprop="telephone">Whatsapp: (11) 91087-7390</p>
        </div>
        <div class="infoItens">
          <figure>
            <img
              src="@/assets/icon/svg/emailIcon.svg"
              class="img"
              alt="Email Scubalab"
            />
          </figure>
          <p itemprop="email">E-mail: contato@scubalab.com.br</p>
        </div>
        <div
          class="infoItens"
          itemscope
          itemtype="https://schema.org/OpeningHoursSpecification"
        >
          <figure>
            <img
              src="@/assets/icon/svg/openingHoursIcon.svg"
              class="img"
              alt="Horário da Scubalab"
            />
          </figure>
          <p itemprop="opens" datetime="00:00:00">
            Segunda a Sexta das 8h às 18h e Sábado das 8h às 12h
          </p>
        </div>
      </article>
      <article class="contactItem">
        <p>Certificadora Internacional:</p>

        <div class="contactItens">
          <figure>
            <img
              src="@/assets/icon/svg/certifier/sdiIcon.svg"
              class="img"
              alt="Scuba Diving Internacional"
            />
          </figure>
          <figure>
            <img
              src="@/assets/icon/svg/certifier/tciIcon.svg"
              class="img"
              alt="Techinical Diving Internacional"
            />
          </figure>
          <figure>
            <img
              src="@/assets/icon/svg/certifier/erdiIcon.svg"
              class="img"
              alt="Techinical Diving Internacional"
            />
          </figure>
          <figure>
            <img
              src="@/assets/icon/svg/certifier/priIcon.svg"
              class="img"
              alt="Techinical Diving Internacional"
            />
          </figure>
          <figure>
            <img
              src="@/assets/icon/svg/certifier/frtIcon.svg"
              class="img"
              alt="Techinical Diving Internacional"
            />
          </figure>
        </div>
      </article>
    </section>

    <hr />
    <section class="linha">
      <div class="logo">
        <figure>
          <img
            src="@/assets/logo-scubalab-mergulho.webp"
            alt="Logo Scubalab Mergulho"
          />
        </figure>
        <div>©Scubalab Mergulho. Todos os direitos reservados.</div>
      </div>
      <div class="socialMedia">
        <figure>
          <a href="https://www.facebook.com/scubalabmergulho" target="blank">
            <img
              src="@/assets/icon/svg/facebook.svg"
              class="img"
              alt="Facebook Scubalab"
            />
          </a>
        </figure>
        <figure>
          <a href="https://www.instagram.com/scubalab/" target="blank">
            <img
              src="@/assets/icon/svg/instagram.svg"
              class="img"
              alt="Instagram Scubalab"
            />
          </a>
        </figure>
        <figure>
          <a href="https://www.youtube.com/scubalabmergulho" target="blank">
            <img
              src="@/assets/icon/svg/youtube.svg"
              class="img"
              alt="Youtube Scubalab"
            />
          </a>
        </figure>
        <!-- <figure>
          <img
            src="@/assets/icon/svg/telegram.svg"
            class="img"
            alt="Telegram Scubalab"
          />
        </figure> -->
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    scrollToTop() {
      // console.log("ativo");
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
/* body {
  font-family: "Open Sans", Roboto, Verdana, "Helvetica Neue", sans-serif;
} */
/* .wppIcon {
  width: 50px;
  height: 50px;
} */
.wppFixed {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
}
.topIcon {
  width: 24px;
  height: 24px;
  stroke: var(--lightBlue);
}
/* RAINBOW */
.wppIcon {
  animation: wppIcon 2s ease-in-out infinite;
  width: 50px;
  height: 50px;
  transform: rotate(10deg);
}
.wppIcon:after {
  animation: wppIcon_shadow 1s ease-in-out infinite;
  transform: rotate(10deg);
  transform-origin: 50% 50%;
}
@keyframes wppIcon {
  50% {
    transform: rotate(-10deg);
  }
}
@keyframes wppIcon_shadow {
  50% {
    transform: rotate(-50deg) translate(10px) scale(0.1);
    opacity: 0.05;
  }
}

.wppIcon {
  fill: white;
  width: 50px;
  height: 50px;
  padding: 3px;
  background-color: #4dc247;
  border-radius: 50%;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  /* box-shadow: 2px 2px 11px rgba(0,0,0,0.7); */
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}

.wppIcon:hover {
  box-shadow: 2px 2px 11px rgba(0, 0, 0, 0.7);
}

/* FIM WPP */
.top:hover > .topIcon {
  stroke: var(--darkGray);
}
.top {
  position: absolute;
  right: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  /* padding: 10px 30px; */
  background-color: var(--darkGray);
  font-size: 0.778em;
  line-height: 20px;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  border: 2px solid var(--lightBlue);
  outline: none;
}
.top:hover {
  background-color: var(--lightBlue);
}
footer {
  display: grid;
  grid-template-columns: 1fr;
  bottom: 0px;
  width: 100%;
  align-content: center;
  background-color: var(--darkGray);
  padding: 20px 0;
  justify-content: center;
}

section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* padding: 0 60px; */
  padding: 0 20px;
  max-width: 1200px;
  width: 100%;
  /* margin: 0 auto; */
}
.containerInfo {
  justify-self: center;
}
.infoItens img {
  width: 21px;
}
.infosItem {
  padding: 60px 0;
}
.infoItens {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  font-style: normal;
  font-size: 0.8em;
  color: var(--white);
  margin: 10px;
  width: 100%;
}
.contactItem {
  display: grid;
  grid-template-columns: 1fr;
  /* align-items: center; */
  font-style: normal;
  font-size: 0.8em;
  color: var(--white);
  margin: 10px 0 0 20px;
  padding: 60px 0;
}
.contactItens {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, max-content));
  justify-content: flex-start;
  grid-gap: 10px;
}

hr {
  width: 100%;
}

.linha {
  display: grid;
  /* grid-template-columns: 1fr 200px; */
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans", Roboto, Verdana, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-size: 0.8em;
  color: #fafafa;
  grid-gap: 10px;
  max-width: 1200px;
  margin: 10px auto;
}

.logo {
  display: grid;
  grid-template-columns: 200px 310px;
  width: 100%;
  justify-items: flex-start;
  align-items: center;
}
.socialMedia {
  display: grid;
  grid-template-columns: repeat(3, 32px);
  justify-content: flex-end;
  grid-gap: 10px;
}

.voltar-topo {
  text-decoration: none;
  padding-bottom: 0;
  position: absolute;
}
@media screen and (max-width: 768px) {
  .linha,
  .logo,
  section {
    grid-template-columns: 1fr;
    padding: 10px 20px;
  }
  .infosItem,
  .contactItem,
  .contactItem p {
    padding: 10px 0;
  }
  .logo,
  .socialMedia {
    justify-items: center;
    justify-content: center;
    grid-gap: 10px;
    padding: 10px 0;
  }
}
</style>